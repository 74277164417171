/**
 * These are a list of all of our app notice strings.
 * TODO: in the future, maybe add localization?
 */

const successNotices = {
  joined: 'Successfully joined team!',
  invited: 'Successfully invited teammates!',
  created: 'Successfully setup new team!',
  enabledDomains: 'The Approved Domains setting has been enabled.',
  setFree: 'Your access is set to free limited.',
  switchedFree: 'Your access is switched to free limited.',
  passwordChanged: 'You successfully changed your password!',
}

const errorNotices = {
  adminOnly: 'Only admins can do this',
  restrictedQuestion: 'Only admins can create or edit questions',
  billingPlansAdminAccess:
    'Sorry, only admins or users with the billing permission are able to upgrade plans. Please contact one of your admins.',
  integrationsAdminAccess:
    'Sorry, only admins or users with the billing permission can access Integrations. Please contact one of your admins.',
  billingAdminAccess:
    'Sorry, only admins or users with the billing permission are able to change billing details. Please contact one of your admins.',
}

export const all_notices = {
  ...successNotices,
  ...errorNotices,
}

export type SuccessNotice = keyof typeof successNotices
export type ErrorNotice = keyof typeof errorNotices

// extract all notice keys as a single union type
export type Notice = keyof typeof all_notices

/**
 * This is a convenience function for returning a specific notice string given
 * a notice key. We are accessing this function rather than the object directly
 * so that future i18n will be easier.
 * @param key the notice string you want to return
 */
export const getNotice = (key: Notice) => all_notices[key]
export const getErrorNotice = (key: Notice) => errorNotices[key]
export const getSuccessNotice = (key: Notice) => successNotices[key]
