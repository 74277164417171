import { Box, Theme, useMediaQuery } from '@mui/material'
import { GlobalEventNotifications } from 'packs/main/TrackSuspiciousActivity/Notifiers/GlobalEventNotifications'
import { UserStateChangedNotification } from 'packs/main/TrackSuspiciousActivity/Notifiers/UserStateChangedNotification'
import { WindowFocusDetect } from 'packs/main/TrackSuspiciousActivity/WindowFocusDetect'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ErrorBoundary } from '../../../dashboard/components/ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../../../dashboard/components/GenericErrorView/GenericErrorView'
import {
  CheatingLeftPadVariantType,
  CheatingPasteDetectionVariantType,
} from '../../../dashboard/components/PadContext/padConfig'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { FloatingDrawingBoard } from '../../../dashboard/components/Whiteboard/FloatingDrawingBoard'
import { useEnvironments } from '../../Environments/EnvironmentsContext/EnvironmentsContext'
import { EnvironmentSelector } from '../../EnvironmentSelector/EnvironmentSelector'
import { EnvironmentSelectorMenuProvider } from '../../EnvironmentSelector/EnvironmentSelectorMenuContext'
import { EmptyEnvironmentsScreen } from '../../EnvironmentsPane/EmptyEnvironmentsScreen'
import { MonacoProvider } from '../../Monaco/EnvironmentMonaco/MonacoContext'
import { selectPadSettings } from '../../selectors'
import { useDrawingModeActions } from './hooks/useDrawingModeActions'
import { useFirebaseUsers } from './hooks/useFirebaseUsers'
import { useLatencyMonitoring } from './hooks/useLatencyMonitoring'
import { useRecordWorkspace } from './hooks/useRecordWorkspace'
import { Horizontal, Stacked } from './layouts'

/**
 * Should return the most logical string representation of a duration
 * it can either be in milliseconds, seconds, or minutes and seconds.
 */
const formatDuration = (duration: number) => {
  if (duration < 1000) {
    return `${duration}ms`
  } else if (duration < 60000) {
    return `${Math.floor(duration / 1000)}s`
  } else {
    return `${Math.floor(duration / 60000)}m ${Math.floor((duration % 60000) / 1000)}s`
  }
}

/**
 * A workspace content containing a left code pane and resources/notes right pane. Includes the "floating" resizable,
 * movable Drawing Mode and the methods necessary to support that.
 */
export const CodeSpace: React.FC<React.PropsWithChildren<unknown>> = () => {
  useRecordWorkspace()
  const { focusTimeEnabled } = useSelector(selectPadSettings)

  const {
    isPlayback,
    takeHome,
    hasEnvironments,
    padOrg,
    cheatingCandidateLeftPadVariant,
    cheatingPasteDetectionVariant,
  } = usePadConfigValues(
    'isPlayback',
    'takeHome',
    'hasEnvironments',
    'padOrg',
    'cheatingCandidateLeftPadVariant',
    'cheatingPasteDetectionVariant'
  )

  const canSeePasteNotifications =
    cheatingPasteDetectionVariant === CheatingPasteDetectionVariantType.Realtime ||
    (cheatingPasteDetectionVariant === CheatingPasteDetectionVariantType.TeamSettings &&
      padOrg?.tsaPasteNotificationEnabled)
  const canSeeLeftPadNotifications =
    cheatingCandidateLeftPadVariant === CheatingLeftPadVariantType.Realtime ||
    (cheatingCandidateLeftPadVariant === CheatingLeftPadVariantType.TeamSettings &&
      padOrg?.tsaDefocusNotificationEnabled)

  const showPasteNotifications = !isPlayback && canSeePasteNotifications
  const showLostFocusNotifications = !isPlayback && canSeeLeftPadNotifications

  const trackFocusDetect =
    (padOrg?.tsaDefocusPlaybackEnabled || padOrg?.tsaDefocusNotificationEnabled) && !isPlayback

  // This is a value used to the force a remount of the MonacoProvider, thus re-establishing the Firepad connection.
  // This is a workaround to help users combat the cases where their editor has encountered a Firepad error and is
  // no longer syncing to Firebase.
  const sentinel = useSelector((state) => state.editorStatus.sentinel)

  useFirebaseUsers()
  useLatencyMonitoring()

  const {
    closeDrawingPanel,
    handleOpenDrawingMode,
    openDrawingModeNewWindow,
    showDrawingModal,
    drawingModeOpen,
  } = useDrawingModeActions()

  const matchesWidthThreshold = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    if (focusTimeEnabled) {
      closeDrawingPanel()
    }
  }, [closeDrawingPanel, focusTimeEnabled])

  const Wrapper = useMemo(() => {
    return hasEnvironments ? EnvironmentSelectorMenuProvider : React.Fragment
  }, [hasEnvironments])

  const { ready, activeEnvironment } = useEnvironments()

  const isEmptyEnvironmentsPad = hasEnvironments && ready && !activeEnvironment
  const content = useMemo(() => {
    if (isEmptyEnvironmentsPad && !isPlayback) {
      return <EmptyEnvironmentsScreen />
    } else {
      return matchesWidthThreshold ? (
        <Stacked handleOpenDrawingMode={handleOpenDrawingMode} />
      ) : (
        <Horizontal handleOpenDrawingMode={handleOpenDrawingMode} />
      )
    }
  }, [handleOpenDrawingMode, isEmptyEnvironmentsPad, matchesWidthThreshold, isPlayback])

  return (
    <Wrapper>
      <MonacoProvider key={sentinel}>
        {showPasteNotifications && (
          <GlobalEventNotifications
            event="pasted-content"
            notification={({ user, event }) => ({
              variant: 'info',
              message: `${user.name} has pasted code.`,
              key: `cheating-detection-paste-${event.time}`,
              autoDismissMs: 2500,
            })}
          />
        )}
        {showLostFocusNotifications && (
          <>
            <UserStateChangedNotification
              shouldNotify={(user) => user.isOutsidePad === true && user.name != null}
              watchValues={['isOutsidePad']}
              notification={(user) => ({
                variant: 'info',
                message: `${user.name} has left the pad.`,
                // Note that the key for this notification is the same as the GlobalEventNotifications key below.
                // This is so that the left/returned notifications for a given user will replace each other instead of
                // stacking up, thus reducing notification fatigue.
                key: `cheating-detection-focus-change-${user.id}`,
                autoDismissMs: 2500,
              })}
            />
            <GlobalEventNotifications
              shouldNotify={({ user }) => user?.name != null}
              event="lost-focus"
              notification={({ user, event }) => ({
                variant: 'info',
                message: `${user.name} has returned to the pad after ${formatDuration(
                  event.data.duration
                )}.`,
                // Note that the key for this notification is the same as the UserStateChangedNotification key, above.
                // This is so that the left/returned notifications for a given user will replace each other instead of
                // stacking up, thus reducing notification fatigue.
                key: `cheating-detection-focus-change-${user.id}`,
                autoDismissMs: 2500,
              })}
            />
          </>
        )}

        {trackFocusDetect && <WindowFocusDetect />}
        <Box height="100%" display="flex">
          {hasEnvironments && (
            <Box flex="none" height="100%">
              <EnvironmentSelector handleOpenDrawingMode={handleOpenDrawingMode} />
            </Box>
          )}
          <Box sx={{ minWidth: 0, ml: 0.25, flex: 1 }}>
            <ErrorBoundary fallback={(e) => <GenericErrorView error={e} />}>
              {content}
            </ErrorBoundary>
          </Box>
          {!takeHome && !isPlayback ? (
            <Box sx={{ display: !showDrawingModal ? 'none' : 'block' }}>
              <FloatingDrawingBoard
                isOpen={drawingModeOpen}
                isDrawingEnabled={drawingModeOpen}
                handleClose={closeDrawingPanel}
                handleNewWindow={openDrawingModeNewWindow}
              />
            </Box>
          ) : null}
        </Box>
      </MonacoProvider>
    </Wrapper>
  )
}
