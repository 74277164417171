import { CircularProgress, styled } from '@mui/material'
import React from 'react'

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}))

/**
 * An interstitial state that is displayed when components are
 * suspended and are waiting for data to load.
 */
export const SuspendedState = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  )
}
