import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'
import { styled } from '@mui/material'
import { TooltipOptions } from 'bootstrap'
import React, { lazy, Suspense, useCallback, useEffect, useRef } from 'react'
import { Rnd } from 'react-rnd'

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../GenericErrorView/GenericErrorView'
import { usePadConfigValue } from '../PadContext/PadContext'
import { SuspendedState } from '../SuspendedState'
import { Whiteboard } from './Whiteboard'

const Excalidraw = lazy(() => import('./Excalidraw/Excalidraw'))

const Bounds = styled('div')({
  position: 'absolute',
  width: 'calc(100% - 80px)',
  height: '100%',
  left: 80,
  overflow: 'hidden',
})

interface IFloatingPanelProps {
  handleClose: () => void
  handleNewWindow: (width?: number, height?: number, zoom?: number, x?: number, y?: number) => void
  isDrawingEnabled: boolean
  isOpen: boolean
}

export const DEFAULT_HEIGHT = 700
export const DEFAULT_WIDTH = 700
const MIN_HEIGHT = 550 // Tall enough to accommodate the drawing toolbar.
const MIN_WIDTH = 550 // A little less than the min width of the code pane.

export const FloatingDrawingBoard: React.FC<React.PropsWithChildren<IFloatingPanelProps>> = ({
  handleClose,
  handleNewWindow,
  isDrawingEnabled,
  isOpen,
}) => {
  const excalidrawEnabled = usePadConfigValue('excalidrawEnabled')
  const externalWindowBtnRef = useRef<HTMLSpanElement>(null)
  const floatingDrawingBoardInnerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const btn = externalWindowBtnRef.current
    if (btn != null) {
      $(btn).tooltip({
        trigger: 'hover',
        placement: 'bottom',
        content: 'Open in new window.',
      } as TooltipOptions) // Force using bootstrap types instead of jquery-ui
      return () => {
        $(btn).tooltip('destroy')
      }
    }
    return
  }, [])

  // The initial coordindates should never be < 80.
  const [coords, setCoords] = React.useState({
    x: Math.max((window.innerWidth - DEFAULT_WIDTH) / 4, 80),
    y: Math.max((window.innerHeight - DEFAULT_HEIGHT) / 4, 0),
  })

  // Initial dimensions should not be greater than the window height/width.
  const [dims, setDims] = React.useState({
    width: Math.min(window.innerWidth, DEFAULT_WIDTH),
    height: Math.min(window.innerHeight, DEFAULT_HEIGHT),
  })

  // Whenever the location or dimensions of the floating panel change, dispatch a window resize event to get Aww
  // to recalculate its canvas positions and sizes.
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [dims.width, dims.height, coords.x, coords.y])

  // Callback to adjust the size of the floating panel when the window itself is resized.
  const adjustPanelSizeLocation = useCallback(() => {
    let { width, height } = dims
    let { x, y } = coords
    let madeAdjustment = false

    if (width > window.innerWidth) {
      // Panel is wider than window, just pin to left and make it same width as window.
      // width = window.innerWidth
      width = Math.max(MIN_WIDTH, window.innerWidth)
      x = 0
      madeAdjustment = true
    } else if (width + x > window.innerWidth) {
      // Panel is positioned far enough right it goes off window. Move it to the left.
      x = window.innerWidth - width
      madeAdjustment = true
    }
    if (height > window.innerHeight) {
      // Panel is taller than window, pin to top and resize to fit in window.
      height = Math.max(MIN_HEIGHT, window.innerHeight)
      y = 0
      madeAdjustment = true
    } else if (height + y > window.innerHeight) {
      // Panel is far enough down it is off window. Move it up.
      y = window.innerHeight - height
      madeAdjustment = true
    }
    // If adjustments to the position/size are necessary, make them.
    if (madeAdjustment) {
      setDims({ width, height })
      setCoords({ x, y })
    }
  }, [coords, dims])

  // On window resizes, adjust the panel size/location if necessary.
  useEffect(() => {
    window.addEventListener('resize', adjustPanelSizeLocation)
    return () => {
      window.removeEventListener('resize', adjustPanelSizeLocation)
    }
  }, [adjustPanelSizeLocation])

  return (
    <Bounds>
      <Rnd
        position={coords}
        size={dims}
        minHeight={MIN_HEIGHT}
        minWidth={MIN_WIDTH}
        bounds="parent"
        className="FloatingDrawingBoard"
        dragHandleClassName="FloatingDrawingBoard-header"
        onResizeStop={(e, direction, ref, delta, position) => {
          // Set the new height/width for the drawing panel.
          setDims({
            width: dims.width + delta.width,
            height: dims.height + delta.height,
          })
          // Set the position of the drawing panel, as it may change depending on which direction the resize happened.
          setCoords(position)
        }}
        onDragStop={(e, d) => {
          setCoords({ x: d.x, y: d.y })
        }}
      >
        <div className="FloatingDrawingBoard-inner" ref={floatingDrawingBoardInnerRef}>
          <div className="FloatingDrawingBoard-header">
            <span>Drawing Mode</span>
            <span>
              <span
                className="FloatingDrawingBoard-header-control"
                onClick={() => handleNewWindow(dims.width, dims.height)}
                title="Open in new window."
                ref={externalWindowBtnRef}
              >
                <LaunchIcon />
              </span>
              <span className="FloatingDrawingBoard-header-control" onClick={handleClose}>
                <CloseIcon />
              </span>
            </span>
          </div>
          {isOpen && (
            <div className="FloatingDrawingBoard-drawing">
              <ErrorBoundary
                fallback={(e) => (
                  <GenericErrorView
                    error={e}
                    message="An error occurred while opening drawing mode."
                  />
                )}
              >
                {excalidrawEnabled ? (
                  <Suspense fallback={<SuspendedState />}>
                    <Excalidraw />
                  </Suspense>
                ) : (
                  <Whiteboard
                    allowInit={isDrawingEnabled}
                    authorId={window.padConfig?.firebaseAuthorId}
                    {...window.padConfig}
                  />
                )}
              </ErrorBoundary>
            </div>
          )}
        </div>
      </Rnd>
    </Bounds>
  )
}
