import {
  CheatingLeftPadVariantType,
  CheatingPasteDetectionVariantType,
} from '../packs/dashboard/components/PadContext/padConfig'

function checkFeatureFlag(key: string): boolean {
  return window.CP_GQL_HYDRATE?.data?.user?.flags?.[key]?.value ?? false
}

export function checkProjectTemplatesFlag() {
  return window.CoderPad.FLAGS.projectTemplates ?? false
}

export function checkProjectNodeJSTemplateFlag() {
  return window.CoderPad.FLAGS.projectNodeJSTemplate ?? false
}

export function checkLspSessionFlag() {
  return window.CoderPad.FLAGS.lspSession ?? false
}

export function checkPlanUpgradeFlowFlag() {
  return checkFeatureFlag('upgrade-downgrade-flow')
}

export function checkDrawingPadsFlag() {
  return checkFeatureFlag('drawing-pads')
}

export function checkCreatePadDropdownFlag() {
  return checkFeatureFlag('add-to-calendar-create-pad-dropdown')
}

export function checkQuestionsRestrictedFlag() {
  return checkFeatureFlag('allow-org-to-restrict-questions')
}

export function checkAllowFocusTimeSettingFlag() {
  return checkFeatureFlag('allow-focus-time-setting')
}

export function checkAllowAiChatSettingFlag() {
  return checkFeatureFlag('allow-ai-chat-setting')
}

export function checkAllowTranscriptionsSettingFlag() {
  return checkFeatureFlag('interview-transcriptions')
}

export function checkTrackSuspiciousActivityPasteFlag() {
  return window.CP_GQL_HYDRATE?.data?.user?.flags?.['cheating-paste-detection']
    ?.value as CheatingPasteDetectionVariantType
}

export function checkTrackSuspiciousActivityLeftPadFlag() {
  return window.CP_GQL_HYDRATE?.data?.user?.flags?.['cheating-candidate-left-pad']
    ?.value as CheatingLeftPadVariantType
}

export function checkEuroPricesFlag() {
  return checkFeatureFlag('euro-prices')
}

export function checkShowApiKeyFlag() {
  return checkFeatureFlag('always-show-api-keys')
}

export function checkProjectQuestionsFlag() {
  return checkFeatureFlag('project-questions')
}

export function checkSharedNavFlag() {
  return checkFeatureFlag('shared-nav')
}

export function checkProductSwitcherFlag() {
  return checkFeatureFlag('product-switcher')
}

export function checkInsightsEnabled() {
  return !!checkFeatureFlag('insights-enabled')
}

export function checkEscapeHatchFlag() {
  return window.CoderPad.FLAGS.environmentsEscapeHatch ?? false
}

export function checkNewPricing2025() {
  return checkFeatureFlag('NEW_PRICING_2025')
}

export function checkSkillMappingFlag() {
  return checkFeatureFlag('SKILL_MAPPING')
}

export function checkDashboardDarkMode() {
  return checkFeatureFlag('interview-dashboard-dark-mode')
}

export function checkPureTranscription() {
  return checkFeatureFlag('pure-transcription')
}

export function checkLegacySsoTransition() {
  return checkFeatureFlag('legacy-sso-transition')
}
export function checkTaTrailExperienceAskMainChallenge() {
  return checkFeatureFlag('ta-trial-experience-ask-main-challenge')
}

export function checkNewTaProductSelectionFlag() {
  return checkFeatureFlag('new-ta-product-selection')
}

export function checkMapInProductSelectionFlag() {
  return checkFeatureFlag('map-in-product-selection')
}

export function checkEnableTrialHomepage() {
  return checkFeatureFlag('ENABLE_TRIAL_HOMEPAGE')
}

export default checkFeatureFlag
