import clsx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Terminal } from 'xterm'
import * as XtermWebfont from 'xterm-webfont'

import { copyPasteOverride } from '../../utils/xterm/copyPasteOverride'
import ResizeDetector from './resize_detector'
import TerminalTheme from './terminal_theme'

// HACK: For console, we have to use visibility: hidden instead of
// display: none, as otherwise the xterm lib's initialization hangs
// the browser tab for a long time.
const consoleInactiveStyle = { visibility: 'hidden' }

class _Console extends React.Component {
  static propTypes = {
    darkColorScheme: PropTypes.bool.isRequired,
    fontSize: PropTypes.number.isRequired,
    language: PropTypes.string,
    hidden: PropTypes.bool.isRequired,
    onTerminalCreate: PropTypes.func.isRequired,
    onTerminalDestroy: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this._root = React.createRef()
    this._terminalCreateCount = 0
  }

  componentDidMount() {
    if (
      !(
        this.props.hidden &&
        (this.props.language === 'python' || this.props.language === 'python3')
      )
    ) {
      this._createTerm(this.props.language)
    }
  }

  componentDidUpdate(prevProps) {
    const term = this._term
    // There is a wee hack for Python here. iPython sends cursor position requests after producing output.
    // If the terminal is hidden, it sends erroneous response values for the cursor position, which
    // causes the cursor to wind up in the wrong place when the terminal is shown again. To compensate for
    // this, we are doing the following:
    // - If the terminal is being hidden and is Python, destroy it.
    // - When showing the terminal, if it is Python, create a new one.
    // - When a Python terminal is unhidden, dispatch an output catch action that will ask execute to send the output history
    //   and repopulate the newly created Python terminal with it.
    const isPython = this.props.language === 'python' || this.props.language === 'python3'
    const isUnhidingPython = !this.props.hidden && prevProps.hidden && isPython

    if (this.props.hidden && isPython) {
      this._destroyTerm()
    } else if (this.props.language !== prevProps.language || isUnhidingPython) {
      this._createTerm(this.props.language)
    } else if (term && this.props.fontSize !== prevProps.fontSize) {
      term.setOption('fontSize', this.props.fontSize)
    } else if (term && this.props.darkColorScheme !== prevProps.darkColorScheme) {
      term.setOption('theme', TerminalTheme[this.props.darkColorScheme ? 'dark' : 'light'])
    }

    if (isPython && isUnhidingPython) {
      this.props.onUnhidePython()
    }

    // Resize the terminal when visibility changes. This makes sure the the terminal fits its container and is
    // scrollable, if necessary.
    if (!this.props.hidden && prevProps.hidden) {
      this._resizeXterm(this._root.current.clientHeight)
    }
  }

  componentWillUnmount() {
    this._destroyTerm()
    this._unmounting = true
  }

  async _createTerm(language) {
    const newTerm = new Terminal({
      cursorBlink: true,
      fontFamily: 'Roboto Mono',
      fontSize: this.props.fontSize,
      lineHeight: 1,
      cols: 73,
      tabStopWidth: 4,
      theme: TerminalTheme[this.props.darkColorScheme ? 'dark' : 'light'],
      rendererType: 'dom',
      screenReaderMode: true,
    })
    newTerm.loadAddon(new XtermWebfont())
    this._destroyTerm()

    const termId = ++this._terminalCreateCount
    await newTerm.loadWebfontAndOpen(this._root.current)

    // address possible race condition
    if (this._unmounting || termId !== this._terminalCreateCount) {
      newTerm.dispose()
      return
    }

    const display = CoderPad.LANGUAGES[language]?.display || 'CoderPad'
    newTerm.write('\n')
    newTerm.write(`\r\n${display} environment ready. Hit run to try out some code!\r\n`)
    this._term = newTerm
    this._resizeXterm(this._root.current.clientHeight)
    this.props.onTerminalCreate(newTerm)

    copyPasteOverride(newTerm)
  }

  _destroyTerm() {
    if (this._term) {
      this.props.onTerminalDestroy()
      this._term.dispose()
      this._term = null
    }
  }

  _handleResize = (width, height) => {
    this._resizeXterm(height)
  }

  _resizeXterm(height) {
    const term = this._term
    if (term) {
      // This reimplements parts of the xterm fit addon.
      //
      // xterm's proposeGeometry will force a synchronous layout to get the parent's
      // width and height (which we already have on resize) and the terminal's and parent's
      // padding (always 0).
      //
      // Also we always keep the columns the same, only changing row count.
      //
      // Beware: this uses parts of xterm that are supposed to be private.
      const proposedRows = Math.floor(
        height / Math.max(term._core._renderService.dimensions.actualCellHeight, 1)
      )
      if (proposedRows !== term.rows) {
        term.resize(73, Math.min(proposedRows, 9999))
      }
    }
  }

  render() {
    return (
      <div
        className={clsx('Console', this.props.className)}
        ref={this._root}
        style={this.props.hidden ? consoleInactiveStyle : null}
      >
        <ResizeDetector ignoreWidth onResize={this._handleResize} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { language } = state.padSettings
  const { darkColorScheme, fontSize } = state.editorSettings

  return {
    darkColorScheme,
    fontSize,
    language,
  }
}

const mapDispatchToProps = {
  onTerminalCreate(term) {
    return {
      type: 'console_created',
      term,
    }
  },
  onTerminalDestroy() {
    return { type: 'console_destroyed' }
  },
  onUnhidePython() {
    return { type: 'console_catchup' }
  },
}

export default connect(mapStateToProps, mapDispatchToProps)(_Console)
