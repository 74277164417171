import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { selectMyUserId } from '../../../selectors'
import SyncHandle from '../../../sync_handle'
import { SystemMessage, Transcript, TranscriptEntryKind, TranscriptResult } from '../../types'

export function useTranscriptionMessages() {
  const selfUserId = useSelector(selectMyUserId)

  const sendSystemMessage = useCallback((message: string) => {
    SyncHandle().set(`transcript/${v4()}`, {
      kind: TranscriptEntryKind.SystemMessage,
      message,
      timestamp: SyncHandle().TIMESTAMP_SENTINEL,
    } as SystemMessage)
  }, [])

  const sendFinalTranscriptResult = useCallback(
    (id: string, result: TranscriptResult) => {
      SyncHandle().set(`transcript/${id}`, {
        kind: TranscriptEntryKind.Transcript,
        transcripts: [result.text || ''],
        preferredTranscript: 0,
        userId: selfUserId,
        timestamp: SyncHandle().TIMESTAMP_SENTINEL,
        duration: result.duration,
        isFinal: true,
      } as Transcript)
    },
    [selfUserId]
  )

  const sendInterimTranscriptResult = useCallback(
    (id: string, result: TranscriptResult) => {
      SyncHandle().set(`transcript/${id}`, {
        kind: TranscriptEntryKind.Transcript,
        transcripts: [result.text || ''],
        preferredTranscript: 0,
        userId: selfUserId,
        timestamp: SyncHandle().TIMESTAMP_SENTINEL,
        duration: result.duration,
        isFinal: false,
      } as Transcript)
    },
    [selfUserId]
  )

  return {
    sendSystemMessage,
    sendFinalTranscriptResult,
    sendInterimTranscriptResult,
  }
}
