import { Box } from '@mui/material'
import { usePadConfigValue } from 'packs/dashboard/components/PadContext/PadContext'
import { SuspendedState } from 'packs/dashboard/components/SuspendedState'
import React, { lazy, Suspense } from 'react'

import { Whiteboard } from '../../../dashboard/components/Whiteboard/Whiteboard'
import CodePane from '../../code_pane'
import { useRecordWorkspace } from '../CodeSpace/hooks/useRecordWorkspace'

const Excalidraw = lazy(() => import('packs/dashboard/components/Whiteboard/Excalidraw/Excalidraw'))

/**
 * A workspace content that renders a Drawing Mode fullscreen (no right pane, and covers the whole window obscuring
 * the pad footer as well).
 */
export const FullScreenDrawing: React.FC<React.PropsWithChildren<unknown>> = () => {
  const excalidrawEnabled = usePadConfigValue('excalidrawEnabled')
  useRecordWorkspace()

  return (
    <>
      <Box className="DrawingContainer" sx={{ height: '100%' }}>
        {excalidrawEnabled ? (
          <Suspense fallback={<SuspendedState />}>
            <Excalidraw />
          </Suspense>
        ) : (
          <Whiteboard authorId={window.padConfig?.firebaseAuthorId} {...window.padConfig} />
        )}
      </Box>
      {/* Need to include the editor, but hidden, so that Firepad can initialize and sync user data */}
      <Box visibility="hidden">
        <CodePane width={'0px'} handleOpenDrawingMode={() => null} />
      </Box>
    </>
  )
}
