import {
  CoderPadIcon,
  CodinGameIcon,
  MapIcon,
} from '@codinpad/shared-components/components/icons/index.js'
import { ProductSwitcherItemProps } from '@codinpad/shared-components/components/productSwitcher/ProductSwitcherItem'
import { Theme, useMediaQuery } from '@mui/material'
import { useUserUpdate } from 'graphql/hooks'
import { Application } from 'graphql/types'
import { track } from 'packs/main/coderpad_analytics'
import React, { ElementType, useCallback, useMemo } from 'react'
import { checkProductSwitcherFlag, checkSkillMappingFlag } from 'utils/checkFeatureFlag'
import { cgBaseUrl } from 'utils/codingameService'
import { Permissions, useUserPermissions } from 'utils/hooks/useUserPermissions'

interface useProductSwitcherItemsParams {
  forced?: boolean
  isScreenSelected?: boolean
}

export function useProductSwitcherItems(
  useProductSwitcherItemsParams?: useProductSwitcherItemsParams
): ProductSwitcherItemProps<'a'>[] | undefined {
  const { forced, isScreenSelected } = useProductSwitcherItemsParams || {}
  const { userHasPermission } = useUserPermissions()

  const useProductSwitcher = checkProductSwitcherFlag() || forced
  const codingameItem = useCodingameItem(userHasPermission(Permissions.ACCESS_MAP))
  const coderpadItem = useCoderpadItem()

  return useMemo(() => {
    if (!useProductSwitcher) {
      return
    }

    const codingameScreenItem = codingameItem[0]
    const codingameOtherItems = codingameItem.slice(1) ?? []
    return [
      ...(userHasPermission(Permissions.ACCESS_SCREENING)
        ? [{ ...codingameScreenItem, selected: isScreenSelected }]
        : []),
      ...(userHasPermission(Permissions.ACCESS_INTERVIEW)
        ? [
            {
              ...coderpadItem,
              selected: !isScreenSelected,
            },
          ]
        : []),
      ...codingameOtherItems,
    ]
  }, [useProductSwitcher, codingameItem, userHasPermission, isScreenSelected, coderpadItem])
}

const screenUrl = `${cgBaseUrl}/dashboard/campaigns/`
const mapUrl = `${cgBaseUrl}/dashboard/map/employees`

export function useCodingameItem(hasMapAccess?: boolean) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isSkillMappingEnabled = checkSkillMappingFlag()
  const { updateUser } = useUserUpdate()
  const handleCodingameClicked = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      track('product switcher product clicked', {
        product: 'CodinGame',
        'vertical position': 1,
      })
      await updateUser({
        currentProduct: Application.Screen,
      })
      window.location.replace(screenUrl)
    },
    [updateUser]
  )
  const handleSkillMappingClicked = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      track('product switcher product clicked', {
        product: 'Skill Mapping',
        'vertical position': 3,
      })
      await updateUser({
        currentProduct: Application.Map,
      })
      window.location.replace(mapUrl)
    },
    [updateUser]
  )

  return useMemo(() => {
    let items: ProductSwitcherItemProps<ElementType>[] = [
      {
        ...(isMobile
          ? {
              logo: <CodinGameIcon size="small" />,
              logoSelected: <CodinGameIcon size="small" state="selected" />,
            }
          : {
              logo: <CodinGameIcon />,
              logoHovered: <CodinGameIcon state="hovered" />,
              logoSelected: <CodinGameIcon state="selected" />,
            }),
        label: 'SCREEN',
        tooltip: <span>Auto-graded tech assessments</span>,
        href: screenUrl,
        onClick: handleCodingameClicked,
      },
    ]

    if (isSkillMappingEnabled && hasMapAccess) {
      items = [
        ...items,
        {
          ...(isMobile
            ? {
                logo: <MapIcon size="small" />,
                logoSelected: <MapIcon size="small" state="selected" />,
              }
            : {
                logo: <MapIcon />,
                logoHovered: <MapIcon state="hovered" />,
                logoSelected: <MapIcon state="selected" />,
              }),
          label: 'MAP',
          tooltip: <span>Engage your team and sharpen their skills</span>,
          href: `${cgBaseUrl}/dashboard/map/employees`,
          onClick: handleSkillMappingClicked,
        },
      ]
    }

    return items
  }, [handleCodingameClicked, hasMapAccess, isMobile, isSkillMappingEnabled])
}

export function useCoderpadItem() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return useMemo(() => {
    return {
      ...(isMobile
        ? {
            logo: <CoderPadIcon size="small" />,
            logoSelected: <CoderPadIcon size="small" state="selected" />,
          }
        : {
            logo: <CoderPadIcon />,
            logoHovered: <CoderPadIcon state="hovered" />,
            logoSelected: <CoderPadIcon state="selected" />,
          }),
      label: 'INTERVIEW',
      tooltip: <span>Collaborative live interview</span>,
      href: '/dashboard/pads',
      onClick: () =>
        track('product switcher product clicked', {
          product: 'CoderPad',
          'vertical position': 2,
        }),
    }
  }, [isMobile])
}
