import { Terminal } from 'xterm'

export function copyPasteOverride(term: Terminal) {
  if (term.element) {
    term.element.addEventListener('contextmenu', (e) => {
      if (term.hasSelection()) {
        navigator.clipboard.writeText(term.getSelection())
      } else {
        navigator.clipboard.readText().then((text) => {
          term.paste(text)
        })
      }
      e.preventDefault()
    })
  }
}
